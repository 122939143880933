import React, { useState, useEffect, useRef } from 'react';

// import ScrollPage from './scroll-page';
import Navigation from './navigation';

import './layout.scss';

const Layout = ( { children } ) => {

    const [ resetScroll, setResetScroll ] = useState( false );
    const [ backgroundColor, setBackgroundColor ] = useState( 'rgb(255, 255, 255)' );
    const intervalRef = useRef();

    useEffect( () => {
        if ( resetScroll ) {
            setTimeout( () => {
                setResetScroll( false );
            }, 100 );
        }
    }, [ resetScroll ] );

    useEffect( () => {

        setDarkness();

        intervalRef.current = setInterval( () => {
            setDarkness();
        }, 1000 * 60 );

        setDarkness();

        return () => {
            clearInterval( intervalRef.current );
        };
    }, [] );

    const setDarkness = () => {

        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        let alpha = 0;

        
        if ( hours <= 6 || hours >= 18 ) {
            // night
            if ( hours === 18 ) {
                alpha = minutes / 60;
            } else if ( hours === 6 ) {
                alpha = ( 60 - minutes ) / 60;
            } else {
                alpha = 1;
            }
        } else {
            // day
            alpha = 0;
        }

        if ( alpha > .5 ) {
            document.body.classList.add( 'text-light' );
        } else {
            document.body.classList.remove( 'text-light' );
        }

        const color = Math.round( 255 - alpha * 255 );
        setBackgroundColor( `rgb(${color}, ${color}, ${color})` );

    };

    return <LayoutContext.Provider value={{ resetScroll, setResetScroll }}>
        <div className="dim" style={{ backgroundColor }}></div>
        <Navigation />
        {/* <ScrollPage> */}
        {children}
        <footer>
            <p className="column-62">
                Insta: <a href="https://www.instagram.com/esmeegeerken/" rel="noreferrer noopener" target="_blank">@esmeegeerken</a><br />
                E-mail: info (at) esmeegeerken (dot) com
            </p>
            <p className="column-38">
                Copyright {new Date().getFullYear()} <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode" rel="noreferrer noopener" target="_blank">(nc-nd)</a>
            </p>
        </footer>
        {/* </ScrollPage> */}
    </LayoutContext.Provider>;
};

export const LayoutContext = React.createContext();
export default Layout;
