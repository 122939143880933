import React from 'react';

import { Link } from 'gatsby';

import './navigation.scss';

const Navigation = () => {

    return <nav className="navigation">
        <Link to={'/'}>Studio Esmee Geerken</Link>
    </nav>;

};

export default Navigation;
